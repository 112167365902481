// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$red:                   #D4451D;
$yellow:                #FFD100;
$yellow-light:          #FFE788;
$brown:                 #696057;

$parchment:             #FFFAE6;
$parchment-dark:        #FFF5D0;

$black:                 #232020;
$black-true:            #000;
$white:                 #fff;
$gray-darker:           #867E76;
$gray-dark:             #A39C96;
$gray:                  #B2ACA7;
$gray-light:            #C1BCB8;
$gray-lighter:          #E0DDDA;
$gray-lightest:         #efefef;

$brand-primary:         $red;
$brand-secondary:       $yellow;
$brand-secondary-alt:   rgba($brand-secondary,.8);
$brand-secondary-alt2:   #FFE788;

$body-bg:               $white;
$text-color:            $gray-darker;

$link-color:            $brand-primary;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: none;


//== Typography
$font-family-sans-serif:  "Gotham SSm A", "Gotham SSm B","Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       "Archer SSm A", "Archer SSm B",Georgia, "Times New Roman", Times, serif;

$font-weight-light:     300; // Gotham: thin, light
$font-weight-base:      400; // Gotham: book
$font-weight-med:       500; // Gotham: medium
$font-weight-bold:      700; // Gotham: bold
$font-weight-head-base: 500; // Archer: light, book
$font-weight-head-bold: 600; // Archer: bold, semibold, medium

$font-size-base:          18px;

$font-size-3xs:           ceil(($font-size-base * 0.5)); // ~9px
$font-size-2xs:           ceil(($font-size-base * 0.6111)); // ~11px
$font-size-xs:            ceil(($font-size-base * 0.6666)); // ~12px
$font-size-sm:            ceil(($font-size-base * 0.7777)); // ~14px
$font-size-sm2:           ceil(($font-size-base * 0.8333)); // ~15px
$font-size-md:            ceil(($font-size-base * 0.8888)); // ~16px
$font-size-lg:            ceil(($font-size-base * 1.1666)); // ~21px
$font-size-xl:            ceil(($font-size-base * 1.3333)); // ~24px
$font-size-2xl:           ceil(($font-size-base * 1.6666)); // ~30px
$font-size-3xl:           ceil(($font-size-base * 1.7777)); // ~33px

$font-size-h1:            ceil(($font-size-base * 3.3333)); // ~60px
$font-size-h2:            ceil(($font-size-base * 2.6666)); // ~48px
$font-size-h3:            ceil(($font-size-base * 2)); // ~36px
$font-size-h4:            ceil(($font-size-base * 1.5555)); // ~28px
$font-size-h5:            ceil(($font-size-base * 1.3333)); // ~24px
$font-size-h6:            ceil(($font-size-base * 1.1111)); // ~20px

$font-size-huge:          ceil(($font-size-base * 4)); // ~72px

$font-size-large:         $font-size-lg;
$font-size-small:         $font-size-sm;

// $line-height-base:        1.428571429; // 20/14
$line-height-base:        1.7; // 20/14
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

$headings-font-family:    $font-family-serif;
$headings-font-weight:    $font-weight-head-base;
$headings-line-height:    1.16;
$headings-color:          inherit;

//== Grid system
$grid-columns:              12;
$grid-gutter-width:         30px;

//== Navbar
// Basics of a navbar
$navbar-height:                    $grid-gutter-width*2;
$navbar-margin-bottom:             $line-height-computed;
$navbar-border-radius:             0;
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2));
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height:       340px;

$navbar-default-color:             #777;
$navbar-default-bg:                $brand-secondary;
$navbar-default-border:            $brand-secondary;

$navbar-default-link-color:                $brown;
$navbar-default-link-hover-color:          $black;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         $brand-primary;
$navbar-default-link-active-bg:            transparent;
$navbar-default-link-disabled-color:       #ccc;
$navbar-default-link-disabled-bg:          transparent;

$navbar-inverse-link-color:                 $white;
$navbar-inverse-link-hover-color:           $brand-secondary;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg:             $navbar-inverse-link-hover-bg;
$navbar-inverse-link-disabled-color:        #444;
$navbar-inverse-link-disabled-bg:           transparent;
$navmenu-width:                             300px;

// Navbar toggle
$navbar-default-toggle-hover-bg:            transparent;
$navbar-default-toggle-icon-bar-bg:         $brown;
$navbar-default-toggle-border-color:        transparent;

//== Buttons
$btn-font-weight:                $font-weight-base;

$btn-default-color:              #333;
$btn-default-bg:                 #fff;
$btn-default-border:             #ccc;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             darken($btn-primary-bg, 5%);

$btn-link-disabled-color:        $gray-light;

$btn-border-radius-base:         0;
$btn-border-radius-large:        $btn-border-radius-base;
$btn-border-radius-small:        $btn-border-radius-base;


//== Forms
$input-bg:                       $gray-lighter;
$input-bg-disabled:              $gray-lighter;
$input-color:                    $text-color;
$input-border:                   $gray-lighter;

$input-border-radius:            0;
$input-border-radius-large:      $input-border-radius;
$input-border-radius-small:      $input-border-radius;
$input-border-focus:             #66afe9;
$input-color-placeholder:        $text-color;

$input-height-base:              $grid-gutter-width*1.5;

$form-group-margin-bottom:       15px;

$legend-color:                   $gray-dark;
$legend-border-color:            #e5e5e5;

$input-group-addon-bg:           $gray-lighter;
$input-group-addon-border-color: $input-border;

$cursor-disabled:                not-allowed;

$carousel-indicator-active-bg:                $brand-secondary;
$carousel-indicator-border-color:             $brand-secondary;

//== Tooltips
$tooltip-max-width:           300px;
$tooltip-color:               #fff;
$tooltip-bg:                  $brand-primary;
$tooltip-opacity:             .9;

$tooltip-arrow-width:         5px;
$tooltip-arrow-color:         $tooltip-bg;


