body {
  padding-top: $navbar-height;
  &.logged-in {
    padding-top: $navbar-height + 46px;
    header.banner {
      top: 46px;
    }
    .page-header {
      margin-top: -46px;
    }
    @media (min-width: 783px) {
      padding-top: $navbar-height + 32px;
      header.banner {
        top: 32px;
      }
      .page-header {
        margin-top: -32px;
      }
    }
  }
  &.home {
    @media (min-width: $screen-sm-min) {
      padding-top: 0;
      &.logged-in {
        padding-top: 46px;
        @media (min-width: 783px) {
          padding-top: 32px;
        }
      }
    }
  }
  &#tinymce {
    padding-top: 0;
  }
  @media (max-width: $screen-xs-max) {
    font-size: $font-size-md;
  }
  &.recipes, &.single-recipe {
    background-color: $parchment;
  }
}
p {
  margin: 0 0 1.25em;
}
.intro-text {
  font-size: $font-size-base;
  font-weight: $font-weight-med;
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-lg;
  }
  @media (min-width: $screen-md-min) {
    font-size: $font-size-xl;
  }
}
img {
  max-width: 100%;
  height: auto;
}
section {
  &.gray {
    background-color: $gray-light;
  }
  &.gray-lighter {
    background-color: $gray-lighter;
  }
  &.red {
    background-color: $brand-primary;
    color: $white;
  }
  &.yellow {
    background-color: $brand-secondary;
    color: $brown;
  }
  &.yellow-alt {
    background-color: $brand-secondary;
    background-color: $brand-secondary-alt;
    color: $brown;
  }
}
.entry-share-btns {
  li {
    margin: 0 $grid-gutter-width/2 0 0;
    background-color: $gray;
    border-radius: $grid-gutter-width;
    a {
      line-height: 30px;
      padding-right: 7px;
      padding-left: 7px;
      color: $parchment;
      svg {
        vertical-align: -2px;
      }
      b {
        display: none;
      }
    }
    &:hover,&:focus {
      background-color: $brand-primary;
    }
  }
}
.tooltip-inner {
  font: $font-size-sm $font-family-serif;
  white-space: nowrap;
  padding: 8px 16px;
}
