.woocommerce {
  .freeshippingalert {
    padding: $grid-gutter-width*.25 $grid-gutter-width*.5;
    background-color: $brand-secondary;
    text-align:center;
    margin-bottom: $grid-gutter-width;
    b {
      font-size: $font-size-lg;
      color: $brand-primary;
    }
  }
  .main.default {
    @extend .vertical-padding-60-bottom;
    @extend .dotted-bottom;
    margin-bottom: $grid-gutter-width;
    @media (min-width: $screen-md-min) {
      margin-bottom: $grid-gutter-width*2;
    }
  }
  input.button.alt {
    @extend .btn;
    @extend .btn-primary;
    font-family: $font-family-sans-serif;
  }
  .woocommerce-message {
    border: none !important;
    background-color: transparent;
    color: $gray-light;
    text-align: right;
    padding: 0 $grid-gutter-width/2 !important;
    margin: $grid-gutter-width 0 !important;
    font-size: $font-size-md;
    .button {
      margin-left: $grid-gutter-width;
      @extend .btn;
      @extend .btn-primary;
      @extend .btn-sm;
    }
    &:before {
      content: none !important;
    }
  }
  .nav-products {
    text-align: center;
    margin-bottom: $grid-gutter-width !important;
    @media (min-width: $screen-md-min) {
      margin-bottom: $grid-gutter-width*2 !important;
    }
    li {
      float: none;
      display: inline-block;
      a {
        color: $gray;
        text-decoration: none;
        font-size: $font-size-sm;
        padding: 0 $grid-gutter-width/5;
        &:hover {
          color: $brand-primary;
        }
        @media (min-width: $screen-sm-min) {
          font-size: $font-size-sm2;
          padding: 0 $grid-gutter-width;
          background: url(../images/nav-separator.png) no-repeat 0 50%;
        }
        @media (min-width: $screen-md-min) {
        }
      }
      &.active {
        a {
          color: $text-color;
        }
      }
      &:first-child {
        a {
          background-image: none;
        }
      }
    }
  }
  .products.row {
    @extend .vertical-padding-120-bottom;
    .product {
      margin-bottom: $grid-gutter-width;
      text-align: center;
      a.woocommerce-LoopProduct-link {
        text-decoration: none;
        display: block;
        h2,h3 {
          font-size: $font-size-h5;
          font-weight: $font-weight-head-bold;
          color: $text-color;
          margin-top: $grid-gutter-width/3;
          &.category-inc {
            margin-bottom: $grid-gutter-width/5;
          }
        }
        .label-group {
          margin-bottom: $grid-gutter-width/2;
          font-size: $font-size-xs;
          text-transform: uppercase;
          color: $gray;
        }
        .price {
          line-height: 1;
          display: block;
          color: $text-color;
          font: $font-weight-base $font-size-base $font-family-sans-serif;
          margin-bottom: $grid-gutter-width/2;
        }
        &:hover {
          h2,h3 {
            color: $brand-primary;
          }
        }
      }
      a.button {
        @extend .btn;
        @extend .btn-addto;
        @extend .btn-sm;
      }
      a.added_to_cart {
        font-size: $font-size-2xs;
        display: block;
        padding-top: 0;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: $font-weight-bold;
        color: $black;
        &::after {
          content: "\e01d";
          font-family: "WooCommerce";
          padding-left: .5em;
        }
        &:hover {
          color: $brand-primary;
        }
      }
    }
  }
  &.single-product {
    .main-product {

      .product {
        .images,.summary {
          @extend .col-xs-12;
          @extend .col-md-6;
        }
        .summary {
          @extend .vertical-padding-60;
          .product_title {
            font-weight: $font-weight-head-bold;
            font-size: $font-size-h4;
            margin-bottom: $grid-gutter-width;
            @media (min-width: $screen-md-min) {
              font-size: $font-size-h3;
            }
          }
          .details {
            @extend .dotted-bottom;
            padding-bottom: $grid-gutter-width;
            .labels {
              font-size: $font-size-sm;
              color: $gray;
              margin-bottom: .2em;
            }
            .price {
              font-size: $font-size-lg;
              font-weight: $font-weight-med;
              margin: 0;
              line-height: 1;
              color: $text-color;
              @media (min-width: $screen-md-min) {
                font-size: $font-size-xl;
              }
            }
            form.cart {
              margin: 0;
              button {
                @extend .btn;
                @extend .btn-addto;
                margin-top: .5em;
              }
              .qty {
                border: none;
                padding-top: 0;
                padding-left: 6px;
                text-align: left;
                font-size: $font-size-lg;
                font-weight: $font-weight-med;
                margin: 0;
                line-height: 1;
                color: $text-color;
                background-color: rgba($gray-lightest,.75);
                @media (min-width: $screen-md-min) {
                  font-size: $font-size-xl;
                }
              }
            }
          }
          .product-description {
            margin: $grid-gutter-width 0;
            font: $font-weight-base $font-size-sm2 $font-family-sans-serif;
            line-height: 1.7 !important;
            p {
              margin-bottom: 1.25em;
            }
            .intro-text {
              line-height: 1.6 !important;
              font: $font-weight-head-base $font-size-base $font-family-serif;
              @media (min-width: $screen-md-min) {
//                 font-size: $font-size-xl;
              }
            }
            .nutritional {
              display: block;
              margin-top: 2em;
            }
          }
          .product_meta {
            font-size: $font-size-sm2;
            text-transform: uppercase;
            a {
              text-transform: none;
            }
          }
        }
      }
    }
  }
  .upsells {
    .upsell-header {
      padding: $grid-gutter-width 0;
      margin-bottom: $grid-gutter-width;
      h2 {
        font-size: $font-size-h4;
        margin: 0;
        text-align: center;
        @media (min-width: $screen-md-min) {
          font-size: $font-size-h3;
        }
      }
    }
    .products.row {
      @extend .vertical-padding-60-bottom;
      .nav-products {
        display: none;
      }
    }
  }
  &.tax-product_cat {
    .nav-products {
      padding-top: $grid-gutter-width*2;
    }
  }
}


.woocommerce-cart {
  .main.default {
    text-align: left;
  }
  .cart_totals {
    h2 {
      font-size: $font-size-h4;
    }
    .wc-forward {
      width: 100%;
    }
  }
}
table.shop_table {
  font-size: $font-size-sm2;
  margin-top: $grid-gutter-width;
  thead {
    background-color: $gray-lighter;
    font-weight: bold;
    text-transform: uppercase;
  }
  tbody {
    .product-name {
      font-family: $font-family-serif;
      font-weight: $font-weight-head-bold;
    }
    .product-subtotal {
      font-weight: $font-weight-bold;
    }
    .product-quantity {
      .quantity {
        .qty {
          padding: .5em;
          padding-left: .1em;
          text-align: left;
          border: none;
        }
      }
    }
  }
  .button {
    @extend .btn;
    @extend .btn-secondary;
    @extend .btn-sm;
    padding: .75em 1.25em !important;
  }
}

.woocommerce-checkout {
  .main.default {
    @extend .col-sm-offset-0;
    @extend .col-sm-12;
    @extend .col-md-offset-0;
    @extend .col-md-12;
    text-align: left;
    h3 {
      font-size: $font-size-h4;
      font-weight: $font-weight-head-bold;
      @extend .dotted-bottom;
      padding-bottom: $grid-gutter-width/2;
      margin-bottom: $grid-gutter-width;
    }
    .form-row {
      margin-bottom: $grid-gutter-width/2;
      label {
        font-size: $font-size-sm;
        text-transform: none;
        line-height: 1;
        font-weight: $font-weight-base;
      }
      &.woocommerce-invalid {
        label {
          color: $text-color;
        }
      }
      .input-text,.select2-choice {
        @extend .form-control;
        font-size: $font-size-sm;
        box-shadow: none;
      }
    }
  }
  .woocommerce-checkout-payment {
    font-family: $font-family-serif;
    .btn {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}
