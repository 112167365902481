.carousel-indicators {
  margin-bottom: 0;
  li {
    width:  10px;
    height: 10px;
    border: 1px solid $white;
    &.active {
      width:  10px;
      height: 10px;
      margin: 1px;
      background-color: $white;
    }
  }
}

.carousel-inner {
  background-color: $black;
  .item {
    img {
      width: 100%;
    }
    .carousel-caption {
      @extend .header-text-shadow;
      width: 100%;
      left: auto;
      right: auto;
      bottom: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin: 0;
      text-align: left;
      h1 {
        margin: 0;
        padding: 0;
        font-size: $font-size-h4;
      }
      p {
        margin: .5em 0 0;
        color: $brand-secondary;
        text-transform: uppercase;
        font-weight: $font-weight-base;
        font-size: $font-size-xs;
        display: none;
      }
      @media (min-width: $screen-sm-min) {
        h1 {
          font-size: $font-size-h2;
        }
        p {
          font-size: $font-size-sm;
          display: block;
        }
      }
      @media (min-width: $screen-md-min) {
        h1 {
          font-size: $font-size-h1;
        }
        p {
          font-size: $font-size-base;
          margin-top: 1em;
        }
      }
      @media (min-width: $screen-lg-min) {
        h1 {
          font-size: $font-size-huge;
        }
      }
      @media (max-width: $screen-xs-max) {
        .container {
          width: 100%;
        }
      }

    }
  }
}

/*
  Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
  CSS from:       http://codepen.io/transportedman/pen/NPWRGq
  and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
  Inspired from:  http://codepen.io/Rowno/pen/Afykb
*/
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
  WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
  Need to override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}
