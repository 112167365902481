.content-info {
  background: #d5dcd4 url(../images/bg/footer.jpg) 50% 0;
  background-size: cover;
  .nav-social {
    list-style: none;
    margin: $grid-gutter-width 0;
    padding: 0;
    text-align: center;
    @media (min-width: $screen-sm-min) {
      text-align: left;
    }
    li {
      display: inline-block;
      margin: 0 $grid-gutter-width/6;
      @media (min-width: $screen-sm-min) {
        margin: 0 $grid-gutter-width/3 0 0;
      }
      a.ss-icon {
        font-size: $font-size-2xl;
        text-decoration: none;
        color: $text-color;
        &:hover {
          color: $link-color;
        }
      }
    }
  }
  #mc_embed_signup {
    padding: $grid-gutter-width 0;
    @media (min-width: $screen-sm-min) {
      padding: $grid-gutter-width*2 0;
    }
    h2 {
      text-align: center;
      margin: 0 0 1em;
      font: $font-weight-base $font-size-base $font-family-sans-serif;
      @media (min-width: $screen-sm-min) {
        font-size: $font-size-lg;
      }
      @media (min-width: $screen-md-min) {
//         font-size: $font-size-xl;
      }
    }
    .form-control {
      background-color: $white;
    }
  }
  .nav-footer {
    list-style: none;
    margin: 0 0 $grid-gutter-width 0;
    padding: 0;
    text-align: center;
    @media (min-width: $screen-sm-min) {
      text-align: right;
      margin-top: 35px;
    }
    li {
      display: inline-block;
      a {
        font-weight: $font-weight-med;
        font-size: $font-size-xs;
        color: $text-color;
        line-height: $font-size-2xl;
        text-transform: uppercase;
        text-decoration: none;
        padding: $grid-gutter-width/3 $grid-gutter-width/5;
        &:hover {
          color: $link-color;
        }
      }
      &:first-child {
        a {
          background-image: none;
        }
      }
      @media (min-width: $screen-sm-min) {
        a {
          background: url(../images/nav-separator.png) no-repeat 0 50%;
          background-size: 1px auto;
          padding: $grid-gutter-width/2;
        }
        &:last-child {
          a {
            padding-right: 0;
          }
        }
      }
    }
  }
}
