.main {
  &.default {
    @extend .vertical-padding-120;
    @extend .col-sm-10;
    @extend .col-sm-offset-1;
    @extend .col-lg-8;
    @extend .col-lg-offset-2;
    text-align: center;
    h2.content-title {
      font-size: $font-size-h4;
      margin-top: 0;
      margin-bottom: $grid-gutter-width;
      line-height: 1.3;
    }
    @media (min-width: $screen-md-min) {
//       font-size: $font-size-lg;
      h2.content-title {
        font-size: $font-size-h3;
        margin-bottom: $grid-gutter-width*2;
      }
    }
  }
  .home & {
    &.default {
      @extend .col-lg-10;
      @extend .col-lg-offset-1;
      h2.content-title {
        font-size: $font-size-h4;
        padding-bottom: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;
        @extend .dotted-bottom;
      }
      @media (min-width: $screen-sm-min) {
        h2.content-title {
          font-size: $font-size-h3;
        }
      }
      @media (min-width: $screen-md-min) {
        h2.content-title {
          font-size: $font-size-h2;
        }
      }
      @media (min-width: $screen-lg-min) {
        h2.content-title {
          font-size: $font-size-h1;
        }
      }
    }
  }
}
.home {
  .triptych {
    @media (max-width: $screen-xs-max) {
      background-color: $brand-secondary-alt;
    }
    .items {
      @extend .clearfix;
      @media (max-width: $screen-xs-max) {
        max-width: 420px;
        margin: 0 auto;
      }
    }
    .item {
      padding: 0;
      position: relative;
      .attachment-triptych {
        width: 100%;
        height: auto;
        display: block;
      }
      .details {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        margin: 0;
        color: $white;
        text-decoration: none;
        .synopsis {
          text-align: center;
          img {
            width: 60px;
          }
          p {
            margin: 10px 0 0;
            @extend .header-text-shadow;
            font: $font-weight-head-base $font-size-h5 $font-family-serif;
            background: url(../images/whiteline.png) no-repeat center bottom;
            padding-bottom: $grid-gutter-width/3;
            @media (min-width: $screen-md-min) {
              font-size: $font-size-h4;
            }
          }
        }
      }
      .description {
        z-index: -10;
        opacity: 0;
        background-color: $brand-primary;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        padding: $grid-gutter-width;
        padding-bottom: $grid-gutter-width/2;
        font-size: $font-size-xs;
        color: $black;
        line-height: 1.5;
        @media (min-width: $screen-md-min) {
          font-size: $font-size-sm;
          padding-bottom: $grid-gutter-width;
        }
        h2 {
          font-size: $font-size-h5;
          color: $brand-secondary-alt2;
          margin-top: 0;
          @media (min-width: $screen-md-min) {
            font-size: $font-size-h3;
          }
          @media (min-width: $screen-lg-min) {
            font-size: $font-size-h2;
          }
        }
        .readmore {
          margin: 0;
          a {
            color: $brand-secondary;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
              color: $white;
            }
          }
        }
      }
      &.faded {
        background-color: $white;
        img {
        	-webkit-filter: grayscale(1);
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
          opacity: .8;
          display: block;
        }
      }
      &.shown {
        .description {
          z-index: 10;
          opacity: 1;
        }
      }
    }
  }
  .home-products {
    @extend .vertical-padding-60-top;
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      .product:last-child {
        display: none;
      }
    }
  }
}
