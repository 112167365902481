article.hentry {
  h1,h2,h3,h4,h5,h6 {
    font-weight: $font-weight-med;
    font-family: $font-family-sans-serif;
  }
  header {
    time {
      display: block;
      color: $gray;
      font-size: $font-size-xs;
      font-weight: $font-weight-base;
      @media (min-width: $screen-md-min) {
        font-size: $font-size-sm;
      }
    }
    .entry-title {
      @extend .dotted-bottom;
      font-size: $font-size-h4;
      margin: $grid-gutter-width*1.5 0 $grid-gutter-width;
      padding: 0 0 $grid-gutter-width*.8;
      @media (min-width: $screen-md-min) {
        font-size: $font-size-h3;
      }
    }
  }
  .entry-content {
    @extend .dotted-bottom;
    font-weight: $font-weight-head-base;
    font-family: $font-family-serif;
    padding-bottom: $grid-gutter-width;
    h1 {
      font-size: $font-size-h3;
    }
    h2 {
      font-size: $font-size-h4;
    }
    h3 {
      font-size: $font-size-h5;
    }
    h4 {
      font-size: $font-size-h6;
    }
    h5 {
      font-size: $font-size-base;
    }
    h6 {
      font-size: $font-size-md;
    }
    hr {
      background: transparent url(../images/dotted-horizontal.png) repeat-x 0 100%;
      margin: $grid-gutter-width 0;
      border: none;
      height: 3px;
    }
  }
  footer {
    padding-top: $grid-gutter-width;
  }
  &.recipe {
    header {
      position: relative;
      .entry-title {
        margin: 0;
        padding: 0;
        font-size: $font-size-h3;
        color: $brand-primary;
        text-align: center;
        background-image: none;
        @media (min-width: $screen-md-min) {
          font-size: $font-size-h2;
        }
      }
      time {
        width: 60px;
        display: block;
        text-align: center;
        font-size: $font-size-xs;
        font-weight: $font-weight-med;
        position: absolute;
        top:-90px;
        right: 0;
        color: $gray-darker;
        z-index: 2;
        b {
          height: 60px;
          display: block;
          line-height: 60px !important;
          font: $font-weight-head-base $font-size-h4 $font-family-serif;
          color: $brand-secondary;
          background: url(../images/bg/redburst.png) no-repeat center center;
          background-size: 100% auto;
        }
        @media (min-width: $screen-sm-min) {
          width: 80px;
          font-size: $font-size-base;
          top:-130px;
          b {
            height: 80px;
            line-height: 80px !important;
            font-size: $font-size-h3;
          }
        }
        @media (min-width: $screen-md-min) {
          width: 90px;
          top:-140px;
          b {
            height: 90px;
            line-height: 90px !important;
          }
        }
        @media (min-width: $screen-lg-min) {
          width: 100px;
          font-size: $font-size-lg;
          top:-170px;
          b {
            height: 100px;
            line-height: 100px !important;
            font-size: $font-size-h2;
          }
        }
      }
    }
    .overview {
      @extend .dotted-bottom;
      margin-top: $grid-gutter-width;
      margin-bottom: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
      font-size: $font-size-lg;
      line-height: 1.5;
      @media (min-width: $screen-md-min) {
        font-size: $font-size-xl;
        margin-top: $grid-gutter-width*2;
        margin-bottom: $grid-gutter-width*2;
      }
      .desc {
        padding-top: $grid-gutter-width/2;
        .detail-time {
          font: $font-weight-bold $font-size-sm $font-family-sans-serif;
          text-transform: uppercase;
          margin-top: 2em;
        }
      }
    }
    .details {
      padding-bottom: $grid-gutter-width;
      font-size: $font-size-sm;
      line-height: 1.5;
      @media (min-width: $screen-sm-min) {
        font-size: $font-size-md;
      }
    }
    .ingredients {
      @extend .clearfix;
      @media (max-width: $screen-xs-max) {
        margin-bottom: $grid-gutter-width;
      }
      h3 {
        margin: 0 0 $grid-gutter-width 30%;
        font: $font-weight-bold $font-size-sm $font-family-sans-serif;
        text-transform: uppercase;
        @media (min-width: $screen-sm-min) {
          font-size: $font-size-md;
        }
      }
      dl {
        dt {
          width: 29%;
          float: left;
          margin-right: 1%;
          clear: left;
          font: $font-weight-bold $font-size-xs $font-family-sans-serif;
          text-transform: uppercase;
          padding-top: 3px;
        }
        dd {
          width: 70%;
          float: left;
          line-height: 1.4;
          margin-bottom: .75em;
        }
      }
      @media (min-width: $screen-md-min) {
        h3 {
          margin-left: 25%;
        }
        dl {
          dt {
            width: 24%;
          }
          dd {
            width: 75%;
          }
        }
      }
    }
    .instructions {
      p {
        margin-bottom: 1.5em;
      }
      strong {
        font-weight: $font-weight-bold;
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
      }
    }
  }
}
