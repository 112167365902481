$grocery-height:    50px;
$grocery-height-xs: 60px;
$grocery-height-sm: 40px;

.grocer-banner {
  position: fixed;
  top: 0;
  left: 0;
  height: $grocery-height;
  z-index: 2000;
  width: 100%;
  background-color: $brand-primary;
  box-shadow: 0px 1px 1px 0px rgba($gray-dark,.5);
  color: $white;
  font-size: 10px;
  font-weight: $font-weight-bold;
  padding: 6px 6px 6px;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: $screen-xs-min) {
    height: $grocery-height-xs;
    font-size: $font-size-xs;
  }
  @media (min-width: $screen-sm-min) {
    height: $grocery-height-sm;
    font-size: $font-size-sm;
    padding-top: 8px;
  }
  @media (min-width: $screen-md-min) {
    font-size: $font-size-md;
    padding-top: 6px;
  }
  a {
    color: $brand-secondary;
    &:hover {
      text-decoration: underline;
    }
  }
}
body {
  &.page-id-40,&.woocommerce,&.home {
    padding-top: $navbar-height + $grocery-height;
    @media (min-width: $screen-xs-min) {
      padding-top: $navbar-height + $grocery-height-xs;
    }
    @media (min-width: $screen-sm-min) {
      padding-top: $navbar-height + $grocery-height-sm;
    }
    .banner {
      top: $grocery-height;
      @media (min-width: $screen-xs-min) {
        top: $grocery-height-xs;
      }
      @media (min-width: $screen-sm-min) {
        top: $grocery-height-sm;
      }
    }
  }
  &.home {
/*
    padding-top: $grocery-height;
    @media (min-width: $screen-xs-min) {
      padding-top: $grocery-height-xs;
    }
*/
    @media (min-width: $screen-sm-min) {
      padding-top: $grocery-height-sm;
    }
  }
}
