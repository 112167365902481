header.banner {
  background-color: $brand-secondary;
  height: $grid-gutter-width*2;
  border: none;
  margin-bottom: 0;
  .container {
    position: relative;
    .navbar-header {
      position: relative;
      .navbar-brand {
        position: absolute;
        left: $grid-gutter-width/2;
        top:9px;
        float: none;
        padding: 0;
        line-height: 1;
        height: auto;
        display: block;
        width: 65px;
        img {
          max-width: 100%;
        }
      }
    }
  }
  &.affix {
    width: 100%;
  }
  .home & {
    @media (min-width: $screen-sm-min) {
      margin-top: -90px;
      transition: all 0.2s linear;
      &.homedisplay {
        margin-top: 0;
        transition: all 0.4s ease;
      }
    }
  }
  .navbar-toggle {
    &:hover {
      .icon-bar {
        background-color: $brand-primary;
      }
    }
  }
}
.navmenu,
.navbar-offcanvas {
  width: $navmenu-width;
  border: none;
  border-radius: 0;
  background-color: $brand-primary;
  .nav-primary {
    margin: 0;
    padding: $grid-gutter-width;
    list-style: none;
    li {
      color: $white;
      margin: ceil($grid-gutter-width*.66) 0;
      font-weight: $font-weight-med;
      font-size: $font-size-xs;
      a {
        color: $white;
        text-decoration: none;
        &:hover {
          color: $brand-secondary;
        }
      }
      &.active {
        a {
          color: $brand-secondary;
        }
      }
      &.menu-request-boves-locally,&.menu-cart {
        margin: $grid-gutter-width 0;
        a {
          line-height: $font-size-xl;
          i {
            font-size: $font-size-xl;
          }
          .fontawesome-text {
            margin-left: .5em;
          }
        }
      }
      &.menu-cart {
        margin-top: $grid-gutter-width*1.5;
        .fontawesome-text {display: none;}
      }
    }
  }
}
.page-header {
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  img {
    width: 100%;
  }
  section {
    @extend .vertical-padding-120;
    line-height: $headings-line-height;
    text-align: center;
    h1 {
      font: $font-weight-head-base $font-size-h4 $font-family-serif;
      margin: 0;
      @media (min-width: $screen-sm-min) {
        font-size: $font-size-h3;
      }
      @media (min-width: $screen-md-min) {
        font-size: $font-size-h2;
      }
      @media (min-width: $screen-lg-min) {
        font-size: $font-size-h1;
      }
    }
    &.yellow {
      background-color: $brand-secondary-alt;
    }
    &.overlay {
      @extend .header-text-shadow;
      color: $white;
      position: absolute;
      background-color: transparent;
      top: 40%;
      transform: translateY(-40%);
      width: 100%;
    }
  }
}
.navbar-main {
  @media (min-width: $screen-sm-min) {
    float: right;
    font-weight: $font-weight-med;
    font-size: $font-size-xs;
    li {
      &.menu-request-boves-locally,&.menu-cart {
        background: url(../images/nav-separator.png) no-repeat 0 50%;
        background-size: 1px auto;
        font-size: $font-size-xl;
      }
      a {
        color: $navbar-default-link-color;
        &:hover {
          color: $navbar-default-link-hover-color;
          background-color: $navbar-default-link-hover-bg;
        }
      }
      &.menu-request-boves-locally {
        margin-left: .5em;
        a {
          color: $brand-primary;
          &:hover {
            color: $black;
          }
        }
      }
      &.menu-cart {
        a {
          padding-right: 0;
        }
      }
      .fontawesome-text {display: none;}
    }
  }
}
.home-navigation {
  @extend .hidden-xs;
  background-color: $white;
  .container {
    position: relative;
    .logo {
      margin: 0;
      padding: 0;
      position: absolute;
      width: 127px;
      left: $grid-gutter-width/2;
      top: -48px;
      z-index: 2;
    }
    .navbar-main {
      &>li {
        &>a {
          padding-top: 7px;
          padding-bottom: 7px;
          &:focus {
            background-color: transparent;
          }
        }
      }
    }
  }
}

