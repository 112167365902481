// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.form-control {
	&::-webkit-input-placeholder {
	  color: $gray;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: $gray;
	}
	&::-moz-placeholder {  /* Firefox 19+ */
	  color: $gray;
	}
	&:-ms-input-placeholder {
	  color: $gray;
	}
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
	@media (max-width: $screen-xs-max) {
    font-size: $font-size-md;
  }
}


.gform_wrapper {
  margin: 0 !important;
  input, textarea, select {
    @extend .form-control;
    box-shadow: none;
    margin-bottom: 1em;
    &.button {
      @extend .btn;
      @extend .btn-primary;
      min-width: 200px;
    }
  }
  .top_label .gfield_label {
    font-size: $font-size-sm;
    font-weight: $font-weight-base !important;
    margin: 0 !important;
  }
  ul.gform_fields {
    li.gfield {
      padding-right: 0 !important;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  ul.gfield_checkbox {
    @extend .clearfix;
    margin-bottom: 1.25em;
    &>li {
      float: left;
      width: 48%;
      margin-right: 2%;
      label {
        font-size: $font-size-sm;
        font-weight: $font-weight-base;
      }
      input {
        height: auto;
        margin: 0 .25em 0 0 !important;
        &:checked {
          &+label {
            font-weight: $font-weight-base !important;
            color: $link-color;
          }
        }
      }
    }
  }
}
