.pum-theme-hello-box {
  .pum-container {
    background: $white url(/wp-content/uploads/2018/04/RavPopup-trans2-1024x622.jpg) no-repeat center center;
    background-size: cover;
  }
  .popmake-title {
    @extend h2;
    font-weight: $font-weight-bold;
    margin-top: 0;
    margin-bottom: .5em;
    color: $red;
  }
  .pum-content {
    color: $black;
    font-size: $font-size-base;
    p {
      margin-bottom: 1.75em;
    }
    &+.pum-close {
      padding: 0 0 2px;
      width: $grid-gutter-width;
      height: $grid-gutter-width;
      font-size: $font-size-sm;
    }
  }
}
