.btn {
  width: auto;
  text-transform: uppercase;
  @media (max-width: $screen-xs-max) {
    font-size: $font-size-md;
  }
  &.btn-addto {
    color: $brand-primary;
    background-color: transparent;
    border: none;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    padding: 0;
    line-height: 1;
    &:hover {
      color: $black;
      background-color: transparent;
      border: none;
    }
    &::before {
      content: "+ ";
    }
    &.btn-sm {
      font-size: $font-size-xs
    }
    &:focus,&:active {
      outline: none !important;
    }

  }
  .btn-secondary {
    background-color: $gray-light;
    color: $text-color;
    &:hover {
      color: $brand-primary;
    }
    &:disabled {
      background-color: $gray-lighter;
      color: $gray;
    }
  }
}
