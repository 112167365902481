.block-catering-form {
  @extend .vertical-padding-120-bottom;
  .desc {
    margin-bottom: $grid-gutter-width;
    font-weight: $font-weight-base;
    font-size: $font-size-md;
    p {
      margin-bottom: 2em;
    }
    h4 {
      font: $font-weight-med $font-size-base $font-family-sans-serif;
      margin: 1.2em 0 .4em;
      &:first-child {
        margin-top: 0;
      }
    }
    .tel {
      font: $font-weight-med $font-size-xl $font-family-sans-serif;
      text-transform: uppercase;
    }
  }
}
.block-image-full {
  img {
    width: 100%;
  }
}
.block-request {
  @extend .dotted-top;
  @extend .vertical-padding-60;
  text-align: center;
  font:$font-weight-head-bold $font-size-h5 $font-family-serif;
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h4;
  }
  @media (min-width: $screen-md-min) {
    font-size: $font-size-h3;
  }
  a {
    color: $text-color;
    text-decoration: none;
    &:hover {
      color: $brand-primary;
    }
  }
}
.block-text {
  @extend .vertical-padding-120;
  .block-title {
    margin: 0 0 .5em;
    font-size: $font-size-h3;
  }
}
.block-headline {
  @extend .vertical-padding-120;
  text-align: center;
  h2 {
    margin: 0;
    font-size: $font-size-h3;
    @media (min-width: $screen-md-min) {
      font-size: $font-size-h2;
    }
    @media (min-width: $screen-lg-min) {
      font-size: $font-size-h1;
    }
  }
}
.block-image-gallery {
  @extend .vertical-padding-60-bottom;
  img {
    margin-bottom: $grid-gutter-width/2;
  }
  .onleft {
    padding-right: 0;
  }
  .onright {
    padding-left: 0;
  }
}
.block-blog {
  @extend .vertical-padding-120;
  .rowentry {
    padding: $grid-gutter-width 0;
    border-top: 1px solid $gray-light;
    font: $font-weight-head-base $font-size-md $font-family-serif;
    &:first-child {
      padding-top: 0;
      border-top: none;
    }
    .thm {
      @media (max-width: $screen-xs-max) {
        margin-bottom: $grid-gutter-width/2;
      }
    }
    time {
      width: $grid-gutter-width*2;
      height: $grid-gutter-width*2;
      background-color: $brand-primary;
      color: $gray-lighter;
      font: $font-weight-med $font-size-3xs $font-family-sans-serif;
      text-align: center;
      text-transform: uppercase;
      display: block;
      padding-top: $grid-gutter-width/5;
      b {
        font-weight: normal;
        display: block;
        color: $white;
        font-weight: $font-weight-base;
        font-size: $font-size-2xl;
      }
    }
    h3 {
      font: $font-weight-med $font-size-lg $font-family-sans-serif;
      margin: $grid-gutter-width 0 0;
      a {
        color: $text-color;
        &:hover {
          color: $brand-primary;
        }
      }
    }
    .details {
      line-height: 1.5;
      .excerpt {
        margin: .5em 0 0;
      }
    }

  }
}
.block-recipes {
  @extend .vertical-padding-120-bottom;
  .recipe {
    @extend .col-sm-10;
    @extend .col-sm-offset-1;
    @extend .col-lg-8;
    @extend .col-lg-offset-2;
    @extend .dotted-bottom;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width*2;
    &:first-child {
      @extend .dotted-bottom-top;
    }
    h3 {
      font: $font-weight-med $font-size-h6 $font-family-sans-serif;
      color: $brand-primary;
      margin: 0;
    }
    time {
      width: 50px;
      display: block;
      float: right;
      text-align: center;
      font-size: $font-size-2xs;
      margin: 0 0 $grid-gutter-width/2 $grid-gutter-width/2;
      b {
        height: 50px;
        display: block;
        line-height: 50px !important;
        font: $font-weight-head-base $font-size-lg $font-family-serif;
        color: $brand-secondary;
        background: url(../images/bg/redburst.png) no-repeat center center;
        background-size: 100% auto;
      }
    }
    .description {
      font: $font-weight-head-base $font-size-sm $font-family-serif;
      margin-top: $grid-gutter-width/2;
      line-height: 1.7;
    }
    @media (min-width: $screen-sm-min) {
      .description {
        font-size: $font-size-md;
      }
      h3 {
        margin: 1em 0 0;
        font-size: $font-size-h5;
      }
      time {
        width: 56px;
        font-size: $font-size-sm;
        b {
          height: 56px;
          line-height: 56px !important;
          font-size: $font-size-xl;
        }
      }
    }
    @media (min-width: $screen-md-min) {
      h3 {
        margin-top: 1.25em;
        font-size: $font-size-h4;
      }
      time {
        width: 68px;
        font-size: $font-size-sm;
        margin-left: $grid-gutter-width;
        b {
          height: 68px;
          line-height: 68px !important;
          font-size: $font-size-2xl;
        }
      }
    }
  }
}
