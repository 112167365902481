.vertical-padding-120 {
  padding-top: $grid-gutter-width*2;
  padding-bottom: $grid-gutter-width*2;
  @media (min-width: $screen-sm-min) {
    padding-top: $grid-gutter-width*3;
    padding-bottom: $grid-gutter-width*3;
  }
  @media (min-width: $screen-lg-min) {
    padding-top: $grid-gutter-width*4;
    padding-bottom: $grid-gutter-width*4;
  }
}
.vertical-padding-120-bottom {
  padding-bottom: $grid-gutter-width*2;
  @media (min-width: $screen-sm-min) {
    padding-bottom: $grid-gutter-width*3;
  }
  @media (min-width: $screen-lg-min) {
    padding-bottom: $grid-gutter-width*4;
  }
}
.vertical-padding-60 {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  @media (min-width: $screen-md-min) {
    padding-top: $grid-gutter-width*2;
    padding-bottom: $grid-gutter-width*2;
  }
}
.vertical-padding-60-bottom {
  padding-bottom: $grid-gutter-width;
  @media (min-width: $screen-md-min) {
    padding-bottom: $grid-gutter-width*2;
  }
}
.vertical-padding-60-top {
  padding-top: $grid-gutter-width;
  @media (min-width: $screen-md-min) {
    padding-top: $grid-gutter-width*2;
  }
}.header-text-shadow {
//   text-shadow: 1px 1px 1px rgba($black,.2);
  text-shadow: none;
}
.dotted-bottom {
  background: url(../images/dotted-horizontal.png) repeat-x left bottom;
}
.dotted-top {
  background: url(../images/dotted-horizontal.png) repeat-x left top;
}
.dotted-bottom-top {
  background: url(../images/dotted-horizontal.png) repeat-x left bottom,url(../images/dotted-horizontal.png) repeat-x left top;
}
